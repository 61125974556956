'use strict';

angular.module('enervexSalesappApp').service('TodosService', function(Todolist, Todo, TodoComment, Attachment, Job, $stateParams) {
	this.getJob = getJob;
	this.removeTodo = removeTodo;
	this.getTodo = getTodo;
	this.createAttachment = createAttachment;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;

	function getJob() {
		return Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		});
	}

	function removeTodo(todo) {
		return Todo.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: todo.todolist,
			todoId: todo._id
		}, todo);
	}

	function getTodo() {
		return Todo.get({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId,
			id: $stateParams.todoId
		});
	}

	function createAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}

	function getComments() {
		return TodoComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todoId: $stateParams.todoId,
		});
	}

	function createComment(newComment) {
		return TodoComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todoId: $stateParams.todoId
		}, newComment);
	}

	function removeComment(comment) {
		return TodoComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todoId: $stateParams.todoId,
			id: comment._id
		});
	}

	function updateComment(comment) {
		return TodoComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todoId: $stateParams.todoId,
			id: comment._id
		}, comment);
	}
});
